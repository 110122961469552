import moment from 'moment';

export const confirmedCases = (patients) => {
    return patients.filter(p => p.labResult === "Confirmed").length;
}

export const negativeTests = (patients) => {
    return patients.filter(p => p.labResult === "Negative").length;
}

export const pendingTests = (patients) => {
    return patients.filter(p => p.labResult === "Pending").length;
}

export const confirmedActive = (patients) => {
    return patients.filter(p => p.isCurrentlyActiveCase).length;
}

export const totalSuspected= (patients) => {
    return patients.filter(p => p.isCurrentlySuspectedCase).length;
}

export const totalDeceased = (patients) => {
    return patients.filter(p => p.outcome === "Deceased").length;
}

export const dailyNewDeceased = (patients) => {
    const yesterdaysDate = moment().subtract(1, 'days').format("YYYY-MM-DD");
    return patients.filter(p => { return ((p.outcome === "Deceased") && (p.outcomeDate.substr(0, 10) === yesterdaysDate)) }).length;
}

export const totalRecovered = (patients) => {
    return patients.filter(p => p.outcome === "Recovered").length;
}

export const totalUnknownOutcome = (patients) => {
    return patients.filter(p => p.outcome === "Unknown").length;
}

export const dailyNewCases = (patients) => {
    const yesterdaysDate = moment().subtract(1, 'days').format("YYYY-MM-DD");
    return patients.filter(p => { return ((p.labResult=== "Confirmed") && (p.labResultDate.substr(0, 10) === yesterdaysDate)) }).length;
}

export const facilityCount = (type, patients) => {
    return patients.filter(p => { return p.currentFacilityType === type }).length;
}

const isYesterday = (dateString) => {
    if (dateString === null) {
        return false;
    }

    return moment(dateString).startOf('day').valueOf() === moment().subtract(1, 'days').startOf('day').valueOf();
}

export const collectedStats = (patients) => {
    let stats = {
        confirmed: 0,
        dailyNewConfirmed: 0,
        suspected: 0,
        testPending: 0,
        active: 0,
        testedNegative: 0,
        recovered: 0,
        deceased: 0
    };

    patients.forEach((patient) => {
        stats.confirmed += patient.labResult === "Confirmed" ? 1 : 0;
        stats.dailyNewConfirmed += ((isYesterday(patient.labResultDate)) && (patient.labResult === "Confirmed")) ? 1 : 0;
        stats.suspected += patient.isCurrentlySuspectedCase ? 1 : 0;
        stats.testPending += patient.labResult === "Pending" ? 1 : 0;
        stats.active += patient.isCurrentlyActiveCase ? 1 : 0;
        stats.testedNegative += patient.labResult === "Negative" ? 1 : 0;
        stats.recovered += patient.outcome === "Recovered" ? 1 : 0;
        stats.deceased += patient.outcome === "Deceased" ? 1 : 0;
    });

    return stats;
}
