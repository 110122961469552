// Reducer - how wee specifiy the application state changes based on certain actions


export default (state, action) => {
    switch(action.type){
        case 'DELETE_TRANSACTION':
            return{
                //create a new state and send it
                ...state,
                transactions: state.transactions.filter(transaction => transaction.id !== action.payload) //send down all transactions except the deleted
            }
        case 'ADD_TRANSACTION':
            return {
                ...state,
                transactions: [action.payload, ...state.transactions]
            }
        case 'UPDATE_GLOBAL_DATA':
            return {
                ...state,
                campaignPatientExamData: action.payload
            }
        case 'FILTER':
            return{
                ...state,
                data: state.data.filter(patient => patient.profile.gender === 'male')
            }
        default:
            return state;
    }

}