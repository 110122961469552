import React from 'react';
import qs from 'qs';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    filterBar: {
        height: "55px",
        display: "flex",
        padding: "0 0 0 20px",
        alignItems: "center",
        borderBottom: "1px solid #CECECE",
    },
    facilitiesSelect: {
        borderRadius: "3px",
        backgroundColor: "#FFFFFF",
        color: "#6e6e7b",
        padding: "3px 5px",
    }
}));

const FilterBar = (props) => {

    const classes = useStyles();
    const { facilities } = props;
    let history = useHistory();
    let defaultQuery = { facility: "all", view_by: "facility" };
    let search = Object.assign(defaultQuery, qs.parse(useLocation().search.substr(1)));

    const handleChange = (e) => {
        search.facility = e.target.value;

        if (search.facility === "all") {
            delete search.facility;
        }

        if (search.view_by === "facility") {
            delete search.view_by;
        }

        history.push(history.location.pathname + qs.stringify(search, { addQueryPrefix: true}));
    }

    return (
        <div className={classes.filterBar}>
            
            <select className={classes.facilitiesSelect} onChange={handleChange} value={search.facility}>
                <option value={"all"}>All Counties</option>
                {facilities.map((f) => (
                    <option key={f.facilityID} value={f.facilityID}>{f.facilityName}</option>
                ))}
            </select>
        </div>
    );

}

export default FilterBar;
