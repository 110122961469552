import React from 'react';
import qs from 'qs';
import { useLocation } from 'react-router-dom';

import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { XYPlot, XAxis, YAxis, HorizontalGridLines, LineSeries, VerticalBarSeries } from 'react-vis';
import moment from 'moment';
import _ from 'lodash';

import "../../node_modules/react-vis/dist/style.css";

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        height: "100vh",
        marginLeft: "355px",
        padding: '0 0 0 20px',
        "& .MuiPaper-root": {
            float: "left",
            width: "536px",
            height: "357px",
            padding: "15px",
            margin: "0 15px 0 0",
        }
    },
    heading: {
        fontSize: "1.2em",
        lineHeight: "1.5em",
        margin: "20px 0 10px",
    },
    chartTitle: {
        fontSize: "0.9em",
        fontWeight: "500",
    },
    plot: {
        margin: "10px 0 0 -10px",
    }
}));

const Overview = (props) => {

    const classes = useStyles();
    const { patients } = props;

    let defaultQuery = { facility: "all", view_by: "facility" };
    let { facility } = Object.assign(defaultQuery, qs.parse(useLocation().search.substr(1)));

    let totalNewConfirmed = [];
    let dailyNewConfirmed = [];
    let totalCount = patients
        .filter(p => { 
            return ((p.labResult === "Confirmed") && (moment(p.labResultDate).isBefore(moment().subtract(14, 'days'), 'day') ))
        })
        .filter(p => {
            return ((p.currentFacilityID === facility) || (facility === "all"));
        })
        .length;

    for (let i=0; i<=14; i++) {
        let dailyNewCount = patients
        .filter(p => { 
            return ((p.labResult === "Confirmed") && (moment(p.labResultDate).isSame(moment().subtract(14 - i, 'days'), 'day') ))
        })
        .filter(p => {
            return ((p.currentFacilityID === facility) || (facility === "all"));
        })
        .length;
        totalCount += dailyNewCount;

        totalNewConfirmed.push({x: i, y: totalCount});
        dailyNewConfirmed.push({x: i, y: dailyNewCount});
    }

    return (
	    <div className={classes.root}>
            <Typography component={"h2"} className={classes.heading}>Confirmed Cases</Typography>
            <Paper>
            <Typography component={"h3"} className={classes.chartTitle}>Total Confirmed Cases</Typography>
            <XYPlot className={classes.plot} height={300} width={500}>
                <HorizontalGridLines />
                <LineSeries
                    curve={'curveMonotoneX'}
                    stroke={"#55a2ac"}
                    strokeWidth={3}
                    data={totalNewConfirmed} />
                <XAxis 
                    tickFormat={(d) => { return moment().subtract(14 - d, 'days').format("MMM D"); }}
                    tickLabelAngle={-45}
                    style={{ fontSize: "0.9em" }}
                />
                <YAxis />
            </XYPlot>
            </Paper>
	        <Paper>
            <Typography component={"h3"} className={classes.chartTitle}>Daily New Confirmed Cases</Typography>
            <XYPlot className={classes.plot} height={300} width={500}>
                <HorizontalGridLines />
                <VerticalBarSeries
                    barWidth={0.4}
                    color={"#a5a5a5"}
                    data={dailyNewConfirmed} />
                <XAxis 
                    tickFormat={(d) => { return moment().subtract(14 - d, 'days').format("MMM D"); }}
                    tickLabelAngle={-45}
                    style={{ fontSize: "0.9em" }}
                />
                <YAxis />
            </XYPlot>
            </Paper>
    </div>
    );

}

export default Overview;
