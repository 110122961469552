import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
    root: {
        borderTop: "1px solid #E3E3E3",
        margin: "40px 0",
    },
    statSection: {
        padding: 0,
        width: "100%",
    },
    sectionTitle: {
        display: "inline-block",
        float: "left",
        margin: "-12px 0 0",
        padding: "0 10px 0 0",
        backgroundColor: '#FFFFFF',

        fontWeight: "500",
    },
    stats: {
        clear: 'left',
    },
}));

const SidePanelSection = (props) => {

    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Typography className={classes.sectionTitle}>
                {props.title}
            </Typography>

            <Box display="flex" flexWrap="wrap" justifyContent="space-between" className={classes.stats}>
                {props.children}
            </Box>   
        </div>
    );

}

export default SidePanelSection;
