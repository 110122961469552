// npm start

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { GlobalProvider } from './context/GlobalState';

import axios from 'axios';

import CssBaseline from '@material-ui/core/CssBaseline';

import Header from './components/Header';
import SidePanel from './components/SidePanel';
import FilterBar from './components/FilterBar';
import Projection from './components/Projection';
import Overview from './components/Overview';
import Details from './components/Details';

const App = () => {
    const [errors, setErrors] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const [patients, setPatients] = useState([]);
    const [facilities, setFacilities] = useState([]);

    useEffect(() => {
        const patientsRequest = axios.get('/api/accounts/kenya/patients.json');
        const facilitiesRequest = axios.get('/api/accounts/kenya/facilities.json');

        axios.all([patientsRequest, facilitiesRequest])
            .then(axios.spread((...responses) => {
                setIsLoaded(true);
                setPatients(responses[0].data);
                setFacilities(responses[1].data);
            }))
            .catch((errors) => {
                setIsLoaded(true);
                setErrors(errors);
            });
    }, [])

    return (
        <GlobalProvider>
            <CssBaseline />
            <Router>

                {errors &&
                <h1>{errors}</h1>
                }

                <Header />

                <SidePanel patients={patients} />

                <FilterBar facilities={facilities} />

                {!isLoaded
                    ? <h1>LOADING...</h1>
                    :
                <Switch>
                    <Route exact path="/">
                        <Projection />
                    </Route>
                        <Route path="/overview">
                        <Overview patients={patients} />
                    </Route>
                    <Route path="/details">
                        <Details facilities={facilities} patients={patients} />
                    </Route>
                </Switch>
                }
            </Router>
        </GlobalProvider>
    );
}


export default App;
