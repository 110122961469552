import React from 'react';
import { withRouter, useLocation, Link } from 'react-router-dom';
import qs from 'qs';

import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
    appBar: {
        backgroundColor: '#27507A',
    },
    nav: {
        position: 'absolute',
        left: '35%',
        '& ul': {
            listStyleType: 'none',
            margin: '5px 0',
            '& li': {
                width: '75px',
                textAlign: 'center',
                margin: '0 20px 0',
                padding: '0 0 7px',
                color: '#FFFFFF',
                float: 'left',
            },
            '& li.active': {
                borderBottom: '3px solid #EFFFFF',
            },
            '& li.active a': {
                fontWeight: '500',
            },
            '& li a, & li a:link, & li a:visited': {
                padding: '5px 0',
                fontSize: '1.0em',
                color: '#FFFFFF',
                textDecoration: 'none',
            }
        }
    }
}));

/* The active menu item is dank, I know. */
const Header = (props) => {

    const classes = useStyles();
    const pathname = useLocation().pathname;
    const defaultQuery = { facility: "all", view_by: "facility" };
    const queryParams = Object.assign(defaultQuery, qs.parse(useLocation().search.substr(1)));
    delete queryParams.view_by;

    if (queryParams.facility === "all") {
        delete queryParams.facility;
    }

    return (
        <AppBar position="static" className={classes.appBar}>
            <Toolbar>
                <Typography component="h1">BackpackEMR | COVID-19 Pandemic Tracker</Typography>

                <Box className={classes.nav}>
                    <ul>
                        <li className={pathname === '/' ? 'active' : ''}><Link to={{ pathname: "/", search: qs.stringify(queryParams) } }>Map</Link></li>
                        <li className={pathname === '/overview' ? 'active' : ''}><Link to={{ pathname: "/overview", search: qs.stringify(queryParams) }}>Overview</Link></li>
                        <li className={pathname === '/details' ? 'active' : ''}><Link to={{ pathname: "/details", search: useLocation().search }}>Details</Link></li>
                    </ul>
                </Box>
            </Toolbar>
        </AppBar>
    );

}

export default withRouter(Header);
