//could 

import React, { createContext, useReducer } from 'react';
import AppReducer from './AppReducer';

//initial state
const initialState = {
    data: [ 
        {
            name: 'John',
            age: 12,
            isTall: true
        },
        {
            name: 'Tim',
            age: 43,
            isTall: false
        }
    ],
    campaignPatientExamData: [],
    campaignID: '5d55d0ca-9a6e-40c5-9298-6bf1faa3048f',
}

// useEffect( async () => {
//     console.log("GlobalState: made it to useEffect for only initial render. Setting state....");

//     const result = await axios.get('http://localhost:5000/api/campaigns/5d55d0ca-9a6e-40c5-9298-6bf1faa3048f');

//     initialState.campaignData  = result;

// }, [])

// Create Context
export const GlobalContext = createContext(initialState);

// Provider component
export const GlobalProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AppReducer, initialState);

    // Actions
    function deleteTransaction(id){
        dispatch({
            type: 'DELETE_TRANSACTION',
            payload: id
        });
    }    

    function addTransaction(transaction) {
        dispatch({
          type: 'ADD_TRANSACTION',
          payload: transaction
        });
    }

    function updateGlobalData(data) {
        dispatch({
            type: 'UPDATE_GLOBAL_DATA',
            payload: data
        });
    }

    return(
        <GlobalContext.Provider value ={{
            data: state.data,
            campaignData: state.campaignData,
            campaignID: state.campaignID,
            campaignPatientExamData: state.campaignPatientExamData,
            updateGlobalData,
            deleteTransaction,
            addTransaction
        }}>
            {children}
        </GlobalContext.Provider>
    )
}