import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';


const useStyles = makeStyles(theme => ({
    statItem: {
        padding: "10px 0 0",
        margin: "0 5px 0 0",
        minWidth: "100px",
        '& .MuiCardContent-root': {
            padding: "5px 0",
        }
    },
    statTitle: {
        fontSize: "0.8em",
    },
    statValue: {
        fontSize: "2.1em",
    }
}));

const StatBox = (props) => {
    const classes = useStyles();

    return (
        <Card className={classes.statItem} elevation={0}>
            <CardContent>
                <Typography className={classes.statTitle}>
                    {props.title}
                </Typography>
                <Typography className={classes.statValue}>
                    {props.value}
                </Typography>
            </CardContent>
        </Card>
    );

}

export default StatBox;
