import React from 'react';
import qs from 'qs';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { makeStyles } from '@material-ui/core/styles';

import { collectedStats } from '../util/stats';

const useStyles = makeStyles(theme => ({
    pivotTable: {
        "& .MuiTableCell-root": {
            borderBottom: "none"
        },
        "& .MuiTableCell-sizeSmall": {
            fontSize: "0.8em",
        }
    }
}));

const RegionTable = (props) => {
    const classes = useStyles();
    const { facilities, patients } = props;

    let defaultQuery = { facility: "all", view_by: "facility" };
    let { facility } = Object.assign(defaultQuery, qs.parse(useLocation().search.substr(1)));

    let regions = _.uniqBy(_.sortBy(facilities, ['regionName']), 'regionID').map(f1 => 
        ({ 
            regionID: f1.regionID, 
            regionName: f1.regionName,
            facilityIDs: facilities.filter(f2 => (f2.regionID === f1.regionID)).flatMap(f3 => (f3.facilityID))
        }));

    return (
        <TableContainer component={Paper} className={classes.pivotTable}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center" colSpan={5}>Daily New</TableCell>
                        <TableCell align="center" colSpan={3}>Outcomes</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Region</TableCell>
                        <TableCell>Confirmed Cases</TableCell>
                        <TableCell>Daily New Confirmed</TableCell>
                        <TableCell>Suspected</TableCell>
                        <TableCell>Test Pending</TableCell>
                        <TableCell>Active</TableCell>
                        <TableCell>Tested Negative</TableCell>
                        <TableCell>Recovered</TableCell>
                        <TableCell>Deceased</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {regions.map((r, index) => {
                        let regionStats = collectedStats(patients.filter(p => {
                            return r.facilityIDs.indexOf(p.currentFacilityID) > -1;
                        }));
                        return (
                            <TableRow key={index} style={ index % 2 ? { background: "#FFFFFF" } : { background: "#EFEFEF" } }>
                                <TableCell>{r.regionName}</TableCell>
                                <TableCell>{regionStats.confirmed}</TableCell>
                                <TableCell>{regionStats.dailyNewConfirmed}</TableCell>
                                <TableCell>{regionStats.suspected}</TableCell>
                                <TableCell>{regionStats.testPending}</TableCell>
                                <TableCell>{regionStats.active}</TableCell>
                                <TableCell>{regionStats.testedNegative}</TableCell>
                                <TableCell>{regionStats.recovered}</TableCell>
                                <TableCell>{regionStats.deceased}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default RegionTable;
