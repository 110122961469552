import React, { useState } from 'react';
import ReactMapGL from 'react-map-gl';

import { makeStyles } from '@material-ui/core/styles';

const mapboxToken = "pk.eyJ1IjoibWl0YzAxODUiLCJhIjoiY2s5OGRxaWhhMDdnNTNmcWg2czA1bzg3ZSJ9.I79z4U5yAXVGImYUC83QyQ";


const useStyles = makeStyles(theme => ({
    root: {
        height: "100vh",
        marginLeft: "355px",
        padding: '0',
        "& img": {
            width: "100%",
        }
    }
}));

const Projection = (props) => {

    const classes = useStyles();
    const [ viewport, setViewport ] = useState({
        width: "100%",
        height: "100%",
        latitude: -0.75,
        longitude: 37.8643668,
        zoom: 5.75 
    });


    return (
	    <div className={classes.root} >
            <ReactMapGL mapboxApiAccessToken={mapboxToken} {...viewport} onViewportChange={setViewport} />
	    </div>
    );

}

export default Projection; // can't call it a Map beacuse that's a reserved word in JavaScript :)
