import React from 'react';
import qs from 'qs';
import { useLocation } from 'react-router-dom';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import { makeStyles } from '@material-ui/core/styles';

import { collectedStats } from '../util/stats';

const useStyles = makeStyles(theme => ({
    pivotTable: {
        "& .MuiTableCell-root": {
            borderBottom: "none"
        },
        "& .MuiTableCell-sizeSmall": {
            fontSize: "0.8em",
        }
    }
}));

const FacilityTable = (props) => {
    const classes = useStyles();
    const { facilities, patients } = props;

    let defaultQuery = { facility: "all", view_by: "facility" };
    let { facility } = Object.assign(defaultQuery, qs.parse(useLocation().search.substr(1)));

    return (
        <TableContainer component={Paper} className={classes.pivotTable}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center" colSpan={6}>Daily New</TableCell>
                        <TableCell align="center" colSpan={3}>Outcomes</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Sub-county</TableCell>
                        <TableCell>Facility Type</TableCell>
                        <TableCell>Confirmed Cases</TableCell>
                        <TableCell>Daily New Confirmed</TableCell>
                        <TableCell>Suspected</TableCell>
                        <TableCell>Test Pending</TableCell>
                        <TableCell>Active</TableCell>
                        <TableCell>Tested Negative</TableCell>
                        <TableCell>Recovered</TableCell>
                        <TableCell>Deceased</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {facilities.filter((o) => {
                        return (facility === "all") ? true : o.facilityID === facility;
                    }).map((facility, index) => {
                        let facilityStats = collectedStats(patients.filter(p => {
                            return p.currentFacilityID === facility.facilityID;
                        }));
                        return (
                            <TableRow key={facility.facilityID} style={ index % 2 ? { background: "#FFFFFF" } : { background: "#EFEFEF" } }>
                                <TableCell>{facility.facilityName}</TableCell>
                                <TableCell>{facility.facilityType}</TableCell>
                                <TableCell>{facilityStats.confirmed}</TableCell>
                                <TableCell>{facilityStats.dailyNewConfirmed}</TableCell>
                                <TableCell>{facilityStats.suspected}</TableCell>
                                <TableCell>{facilityStats.testPending}</TableCell>
                                <TableCell>{facilityStats.active}</TableCell>
                                <TableCell>{facilityStats.testedNegative}</TableCell>
                                <TableCell>{facilityStats.recovered}</TableCell>
                                <TableCell>{facilityStats.deceased}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default FacilityTable;
