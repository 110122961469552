import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'qs';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import RawButton from '@material-ui/core/Button';

import RegionTable from './RegionTable';
import FacilityTable from './FacilityTable';

const useStyles = makeStyles(theme => ({
    root: {
        height: "100vh",
        marginLeft: "355px",
        padding: '10px 20px 0 20px',
        '& .MuiPaper-root': {
            margin: '10px 0 0 0',
        },
    },
    buttonBox: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
    },
    viewBy: {
        fontSize: "1em",
        fontWeight: 500,
        lineHeight: 1.8,
    },
    buttonBar: {
        margin: "0 0 0 18px",
        "& .MuiButton-root": {
            fontSize: "0.7em",
            textTransform: "none",
        },
        "& .MuiButton-outlined": {
            padding: "4px 15px",
        }
    }
}));

const DEFAULT_VIEW = "sub-county";

const Button = (props) => {
    let history = useHistory();
    const slug = props.children.toLowerCase().replace(/ /g, "_");
    const defaultQuery = { facility: "all", view_by: DEFAULT_VIEW };
    const search = Object.assign(defaultQuery, qs.parse(useLocation().search.substr(1)));
    let disabled = false;

    if (slug === search.view_by) {
        disabled = true;
    }

    search.view_by = slug;
    if (search.facility === "all") { delete search.facility; }
    if (search.view_by === DEFAULT_VIEW) { delete search.view_by; }

    if (disabled) {
        return <RawButton {...props} disabled>{props.children}</RawButton>;
    } else {
        return <RawButton {...props} onClick={() => { 
            history.replace("/details" + qs.stringify(search, { addQueryPrefix: true} )); 
        }}>{props.children}</RawButton>;
    }
}

const Details = (props) => {

    const classes = useStyles();
    const { facilities, patients } = props;

    const { view_by } = Object.assign({ view_by: DEFAULT_VIEW }, qs.parse(useLocation().search.substr(1)));

    return (
        <div className={classes.root}>
            <Box className={classes.buttonBox}>
                <Typography component="h3" className={classes.viewBy}>View By</Typography>
                <ButtonGroup className={classes.buttonBar}>
                    <Button>County</Button>
                    <Button>Sub-county</Button>
                    {/*
                    <Button>Facility Type</Button>
                    <Button>Date</Button>
                    <Button>Address 1</Button>
                    <Button>Age Group</Button>
                    <Button>Occupation</Button>
                    <Button>Gender</Button>
                    */}
                </ButtonGroup>
            </Box>
            <Box>
                {view_by === "county" &&
                <RegionTable patients={patients} facilities={facilities} /> }

                {view_by === "sub-county" &&
                <FacilityTable patients={patients} facilities={facilities} /> }

            </Box>
        </div>
    );

}

export default Details;
