import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import * as stats from '../util/stats.js';

const useStyles = makeStyles(theme => ({
    breakdown: {
        fontSize: "0.8em"
    }
}));

const SiteBreakdown = ({ patients }) => {
    const classes = useStyles();

    return (
        <div>
            <Typography className={classes.breakdown}>
                Self-Quarantine: <strong>{stats.facilityCount("Self-Quarantine", patients)}</strong><br />
                Quarantine Site: <strong>{stats.facilityCount("Quarantine Site", patients)}</strong><br />
                Isolation Site: <strong>{stats.facilityCount("Isolation Center", patients)}</strong><br />
                Hospital: <strong>{stats.facilityCount("Hospital", patients)}</strong>
            </Typography>
        </div>
    );

}

export default SiteBreakdown;
