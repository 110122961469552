import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import SidePanelSection from './SidePanelSection';
import StatBox from './StatBox';
import SiteBreakdown from './SiteBreakdown';

import * as stats from '../util/stats.js';

const useStyles = makeStyles(theme => ({
    sidePanel: {
        backgroundColor: '#FFFFFF',
        float: "left",
        width: "355px",
        padding: "20px 20px 100px",
    },
    title: {
        fontSize: "1.4em"
    },
    org: {
        fontSize: "0.8em"
    }
}));

const SidePanel = ({ patients }) => {
    const classes = useStyles();

    return (
        <Box className={classes.sidePanel}>
            <Typography className={classes.title} component="h2">
                COVID-19 Tracking - Kenya
            </Typography>
            <Typography className={classes.org} component="h3">
                Medical Records Institute
            </Typography>

            <SidePanelSection title="Test Results">
                <StatBox title="Total Confirmed Cases" value={stats.confirmedCases(patients)} />
                <StatBox title="Daily New Cases" value={stats.dailyNewCases(patients)} />
                <StatBox title="Pending Tests" value={stats.pendingTests(patients)} />
            </SidePanelSection>
            <SidePanelSection title="Active Cases">
                <Box>
                    <StatBox title="Total Active Cases" value={stats.confirmedActive(patients)} />
                    <SiteBreakdown patients={patients.filter(p => { return p.isCurrentlyActiveCase })} />
                </Box>
                <Box>
                    <StatBox title="Total Suspected" value={stats.totalSuspected(patients)} />
                    <SiteBreakdown patients={patients.filter(p => { return p.isCurrentlySuspectedCase })} />
                </Box>
            </SidePanelSection>
            <SidePanelSection title="Closed Cases">
                <StatBox title="Total Deceased" value={stats.totalDeceased(patients)} />
                <StatBox title="Daily New Deceased" value={stats.dailyNewDeceased(patients)} />
                <StatBox title="Total Recovered" value={stats.totalRecovered(patients)} />
                <StatBox title="Tested Netagive" value={stats.negativeTests(patients)} />
                <StatBox title="Total Unknown Outcome" value={stats.totalUnknownOutcome(patients)} />
            </SidePanelSection>
        </Box>
    );

}

export default SidePanel;
